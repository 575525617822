import React, { Fragment, useCallback, useMemo, useState, useRef, useEffect } from 'react'
import { usePopper } from 'react-popper'
import OutsideClickHandler from 'react-outside-click-handler'
import cn from 'clsx'

import Menu, { MenuItem } from 'shared/components/Menu'
import { SelectOption, RenderMenuItemProps } from 'shared/components/interfaces'
import { ReactComponent as ArrowIcon } from 'shared/assets/icons/arrow_down.svg'
import { ReactComponent as CloseIcon } from 'shared/assets/icons/close.svg'
import { ChemistryDefault, ChemistryReset } from 'shared/constants'
import useCaptureEvents from 'shared/hooks/useCaptureEvents'

import Tooltip from '../Tooltip'

import styles from './Select.module.css'

type ValueType<T = string | number> = SelectOption<T> | Array<SelectOption<T>> | null

interface SelectProps<T = string> {
  value?: ValueType<T>
  inputValue?: string
  defaultInputValue?: string
  options: Array<SelectOption<T>>
  name?: string
  placeholder?: string
  disabled?: boolean
  open?: boolean
  isClearable?: boolean
  isSearchable?: boolean
  withClearedInput?: boolean
  isMulti?: boolean
  isLoading?: boolean
  testId?: string
  tabIndex?: string
  theme?: string
  fullWidth?: boolean
  closeOnChange?: boolean
  className?: string
  classNameContainer?: string
  classNameInput?: string
  classNameValue?: string
  classNameIcon?: string
  classNamePlaceholder?: string
  classNameMenu?: string
  classNameMenuItem?: string
  classNameMenuList?: string
  classNameSelected?: string
  onChangeInput?(value: string): void
  onChange?(value: ValueType<T>, name?: string): void
  renderMenuItem?(props: RenderMenuItemProps<T>): React.ReactElement
  renderValue?(value: ValueType<T>): React.ReactElement
  needInfiniteScroll?: boolean
  maxSelectedValues?: number
  withTooltipItem?: boolean
  tooltipTitle?: string
  disableTooltip?: boolean
  readonly?: boolean
  CustomArrowIcon?: React.FC<React.SVGProps<SVGSVGElement>>
}

interface MultiValueContainerProps<T> {
  selected: Array<SelectOption<T>>
  className?: string
}

function MultiValueContainer<T>({
  selected = [],
  className,
}: React.PropsWithChildren<MultiValueContainerProps<T>>): React.ReactElement {
  return (
    <div className={cn(styles.selectComponent__value, className)}>
      {selected.map((option: SelectOption<T>): string => option.label).join(', ')}
    </div>
  )
}

function getOptionValue<T>(option: SelectOption<T>): T {
  return option.value
}

function getOptionLabel<T>(option: SelectOption<T>): string {
  return option.label
}

function getOptionIcon<T>(option: SelectOption<T>): React.ComponentType<React.SVGProps<SVGSVGElement>> | undefined {
  return option.icon
}

function Select<T = string>({
  value: valueProp,
  inputValue: inputValueProp,
  defaultInputValue = '',
  options = [],
  name,
  disabled,
  placeholder = 'Выбрать',
  open = false,
  isClearable = true,
  isSearchable = true,
  withClearedInput = false,
  isMulti = false,
  theme,
  closeOnChange = true,
  onChange,
  onChangeInput,
  renderMenuItem,
  renderValue,
  fullWidth = false,
  className,
  classNameContainer,
  classNameInput,
  classNameValue,
  classNameIcon,
  classNamePlaceholder,
  classNameMenu,
  classNameMenuList,
  classNameMenuItem,
  classNameSelected,
  maxSelectedValues,
  withTooltipItem,
  disableTooltip,
  tooltipTitle,
  readonly,
  needInfiniteScroll,
  CustomArrowIcon,
}: React.PropsWithChildren<SelectProps<T>>): React.ReactElement {
  const getValue = (): ValueType<T> => {
    if (valueProp) {
      return valueProp
    }
    if (isMulti) {
      return []
    }
    return null
  }

  const initialValue: ValueType<T> = getValue()
  const [value, changeValue] = useState<ValueType<T>>(initialValue)
  const [openMenu, toggleMenu] = useState<boolean>(Boolean(open))
  const [inputValue, setInputValue] = useState<string>(defaultInputValue)

  const rootRef = useRef<HTMLDivElement | null>(null)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const menuRef = useRef<HTMLDivElement | null>(null)

  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)

  const { styles: stylesPopper, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
  })

  const inputDisabled = useMemo(() => {
    if (value instanceof Array) {
      return !!(maxSelectedValues && value.length >= maxSelectedValues)
    }
    if (disabled) return true
    return undefined
  }, [maxSelectedValues, value])

  useEffect(() => {
    const { current: menu } = menuRef

    if (openMenu && value && menu) {
      const index = options.findIndex(option => {
        if (Array.isArray(value) && value.length) {
          return option.value === value[0]?.value
        }
        return !Array.isArray(value) && option.value === value.value
      })

      if (index !== -1) {
        const menuList = menu.querySelector(`ul`)
        const menuItem = menuList?.childNodes.item(index)
        const menuHeight = Math.floor(menu.getBoundingClientRect().height / 2)

        if (menuList && menuItem) {
          const top = (menuItem as HTMLElement).offsetTop - menuHeight
          menuList.scroll({
            top,
          })
        }
      }
    }
  }, [value, options, openMenu])

  useEffect((): void => {
    const { current: inputElement } = inputRef
    if (openMenu) {
      inputElement?.focus()
      return
    }
    inputElement?.blur()
  }, [openMenu])

  useEffect((): void => {
    if (valueProp !== undefined) {
      changeValue(valueProp)
    }
  }, [valueProp])

  useEffect((): void => {
    if (inputValueProp !== undefined) {
      setInputValue(inputValueProp)
    }
  }, [inputValueProp])

  const isVisiblePlaceholder = useMemo((): boolean => !value || (value instanceof Array && !value.length), [value])

  const isHasValue: boolean = useMemo((): boolean => {
    if (value instanceof Array) {
      return Boolean(value.length)
    }

    return Boolean(value)
  }, [value])

  const optionsList = useMemo(
    (): Array<SelectOption<T>> =>
      options.filter(
        (option: SelectOption<T>): boolean => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1,
      ),
    [options, inputValue],
  )

  const isOptionSelected = (option: SelectOption<T>, selected: ValueType<T>): boolean => {
    if (selected instanceof Array) {
      if (selected.indexOf(option) > -1) return true

      const candidate = getOptionValue(option)
      return selected.some((i: SelectOption<T>): boolean => getOptionValue(i) === candidate)
    }
    return selected?.value === option.value
  }

  const handleToggle = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    useCaptureEvents()(event)

    toggleMenu(!openMenu)
  }

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()

    const { value: text } = event.target
    setInputValue(text)

    if (onChangeInput) {
      onChangeInput(text)
    }
  }

  const changeSelectionValue = (nextValue: SelectOption<T>[] | SelectOption<T>, name: string | undefined) => {
    changeValue(nextValue)

    if (onChange) {
      onChange(nextValue, name)
    }
  }

  const handleChange = useCallback(
    (option: SelectOption<T>) => (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
      useCaptureEvents()(event)

      const getNextValue = (value: SelectOption<T>[]) => {
        const candidate = getOptionValue(option)

        if (isOptionSelected(option, value))
          return value.filter((i: SelectOption<T>): boolean => getOptionValue(i) !== candidate)

        return maxSelectedValues && value?.length >= maxSelectedValues ? value : [...value, option]
      }

      const nextValue = value instanceof Array && isMulti ? getNextValue(value) : option
      changeSelectionValue(nextValue, name)

      if (closeOnChange && !isMulti) {
        toggleMenu(false)
      }

      setInputValue('')

      if (onChangeInput) {
        onChangeInput('')
      }

      inputRef.current?.focus()
    },
    [onChange, onChangeInput, name, isMulti, closeOnChange, value],
  )

  const handleClose = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
      const { current } = rootRef

      if (current && !current.contains(event.target as Node) && openMenu) {
        toggleMenu(false)
      }
      if (withClearedInput && initialValue && !(initialValue instanceof Array)) {
        handleChange(initialValue)
        setInputValue('')
      }
    },
    [openMenu, withClearedInput, initialValue],
  )

  const handleClear = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
      useCaptureEvents()(event)
      if (isClearable && value) {
        const emptyValue: [] | null = isMulti ? [] : null

        changeValue(emptyValue)

        if (onChange) {
          onChange(emptyValue, name)
        }
      }
    },
    [isClearable, value, isMulti, onChange, name],
  )

  const handleClearLink = useMemo(() => {
    return isClearable ? handleClear : undefined
  }, [isClearable, handleClear])

  const menuItemsList = optionsList.map(
    (option: SelectOption<T>): React.ReactElement => {
      const selected: boolean = isOptionSelected(option, value)
      const key = String(option.value)
      const onChangeHandler = handleChange(option)
      const Icon = option.icon
      if (renderMenuItem) {
        return withTooltipItem && tooltipTitle ? (
          <Tooltip title={tooltipTitle} disabled={disableTooltip}>
            <div key={key}>{renderMenuItem({ option, selected, onChange: onChangeHandler })}</div>
          </Tooltip>
        ) : (
          <Fragment key={key}>{renderMenuItem({ option, selected, onChange: onChangeHandler })}</Fragment>
        )
      }

      return (
        <MenuItem
          key={key}
          className={classNameMenuItem}
          classNameSelected={classNameSelected}
          onClick={onChangeHandler}
          selected={selected}
        >
          {Icon && option.label !== ChemistryReset && <Icon className={styles.selectComponent__menuItemIcon} />}
          {option.label}
        </MenuItem>
      )
    },
  )

  const Input = isSearchable ? (
    <input
      className={cn(
        styles.selectComponent__input,
        {
          [styles.selectComponent__input_filled]: inputValue.length,
        },
        classNameInput,
      )}
      value={inputValue}
      onChange={handleChangeInput}
      ref={inputRef}
      disabled={inputDisabled}
      type="text"
      readOnly={readonly}
    />
  ) : null

  const DefaultIconValue = value && !(value instanceof Array) && getOptionIcon(value)
  const isDefaultValue = value && !(value instanceof Array) && getOptionLabel(value) === ChemistryDefault
  const isResetValue = value && !(value instanceof Array) && getOptionLabel(value) === ChemistryReset

  const valueInsideInput = !inputValue.length ? (
    <>
      {isVisiblePlaceholder && (
        <div className={cn(styles.selectComponent__placeholder, classNamePlaceholder)}>{placeholder}</div>
      )}

      {value && value instanceof Array && Boolean(value.length) && (
        <MultiValueContainer selected={value} className={classNameValue} />
      )}

      {value && !(value instanceof Array) && (
        <div
          className={cn(
            styles.selectComponent__value,
            {
              [styles.selectComponent__value_dark]: theme === 'dark',
              [styles.selectComponent__value_default]: (isDefaultValue || isResetValue) && DefaultIconValue,
            },
            classNameValue,
          )}
        >
          {value && !isDefaultValue && !isResetValue && (renderValue ? renderValue(value) : getOptionLabel(value))}
          {value && getOptionIcon(value) && (isDefaultValue || isResetValue) && DefaultIconValue && (
            <DefaultIconValue />
          )}
        </div>
      )}
    </>
  ) : null

  const arrowSelectIcon = CustomArrowIcon ? <CustomArrowIcon /> : <ArrowIcon />
  const rightInputIcon = isHasValue && isClearable ? <CloseIcon /> : arrowSelectIcon

  const menu = openMenu ? (
    <div
      className={styles.selectComponent__popup}
      ref={setPopperElement}
      style={stylesPopper.popper}
      {...attributes.popper}
    >
      <OutsideClickHandler onOutsideClick={handleClose}>
        <Menu
          className={cn(
            styles.selectComponent__menu,
            { [styles.selectComponent__menu_dark]: theme === 'dark' },
            classNameMenu,
          )}
          classNameList={classNameMenuList}
          open={openMenu}
          ref={menuRef}
          needInfiniteScroll={needInfiniteScroll}
        >
          {menuItemsList}
        </Menu>
      </OutsideClickHandler>
    </div>
  ) : null

  return (
    <div className={cn(styles.selectComponent, className)} ref={rootRef}>
      <div
        className={cn(
          styles.selectComponent__container,
          {
            [styles.selectComponent__container_open]: openMenu,
            [styles.selectComponent__container_disabled]: disabled,
            [styles.selectComponent__container_dark]: theme === 'dark',
            [styles.selectComponent__container_fullWidth]: fullWidth,
          },
          classNameContainer,
        )}
        onClick={disabled ? undefined : handleToggle}
        role="button"
        ref={setReferenceElement}
        tabIndex={-1}
      >
        {Input}

        {valueInsideInput}

        {!disabled && (
          <div
            className={cn(styles.selectComponent__icon, classNameIcon, {
              [styles.selectComponent__icon_open]: openMenu,
              [styles.selectComponent__icon_arrow]: !isHasValue,
              [styles.selectComponent__icon_close]: isHasValue,
            })}
            onClick={handleClearLink}
            onKeyDown={undefined}
            role="button"
            aria-label="select-icon"
            tabIndex={-1}
          >
            {rightInputIcon}
          </div>
        )}
      </div>

      {menu}
    </div>
  )
}

export default Select
