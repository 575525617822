import React, { useEffect, useMemo, useState } from 'react'
import cn from 'clsx'

import Dialog, { DialogContent } from 'shared/components/Dialog'
import { ReactComponent as SuccessIcon } from 'shared/assets/icons/success.svg'
import { ReactComponent as ErrorIcon } from 'shared/assets/icons/error.svg'

import styles from './DialogStatus.module.css'

interface DialogStatusProps {
  title: string
  content?: React.ReactElement
  status: 'success' | 'error'
  open?: boolean
  duration?: number
  customIcon?: React.FC<React.SVGProps<SVGSVGElement>>
  classNameCustomIcon?: string
  errors?: string
  onClose(): void
}

const DialogStatus: React.FC<DialogStatusProps> = ({
  open = true,
  title,
  content,
  status,
  onClose,
  duration = 2000,
  customIcon: CustomIcon,
  errors,
}): React.ReactElement => {
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (open) {
      const timeout = setTimeout(onClose, duration)
      setTimeoutId(timeout)
    }
  }, [open, onClose, duration])

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [timeoutId])

  const colorStatus: string = useMemo(() => (errors ? 'error' : status), [errors, status])

  return (
    <Dialog open={open} size="small" title={errors || title} classNameModalHeader={styles.dialogStatus__title}>
      <DialogContent className={cn(styles.dialogStatus__content, styles[colorStatus])}>
        {content}
        {!CustomIcon && (
          <>
            {status === 'success' && !errors && <SuccessIcon />}
            {(status === 'error' || errors) && <ErrorIcon />}
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default DialogStatus
