import React from 'react'

import Typography from 'shared/components/Typography'
import { ReactComponent as SendMailIcon } from 'shared/assets/icons/send_mail.svg'

import styles from './AuthInfo.module.css'

type Props = {
  title: string
  description?: string
}

export const AuthInfo: React.FC<Props> = ({ title, description }) => {
  return (
    <div className={styles.info}>
      <SendMailIcon />
      <Typography size="l" weight="bold" uppercase>
        {title}
      </Typography>
      <Typography weight="light" size="xs" spacy>
        {description}
      </Typography>
    </div>
  )
}
