/* eslint-disable import/no-duplicates */
import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { usePopper } from 'react-popper'
import InputMask from 'react-input-mask'
import cn from 'clsx'
import { nanoid } from 'nanoid'
import { ru } from 'date-fns/locale'
import { format, formatISO, addMonths, addYears, isSameMonth, getYear, getMonth } from 'date-fns'
import OutsideClickHandler from 'react-outside-click-handler'

import ButtonIcon from 'shared/components/ButtonIcon'
import Typography from 'shared/components/Typography'
import { ReactComponent as Arrow } from 'shared/assets/icons/arrow_long.svg'
import { ReactComponent as CalendarIcon } from 'shared/assets/icons/calendar.svg'
import calendarMatrix, { CalendarMatrix } from 'shared/utils/calendarMatrix'
import Input from 'shared/components/Input'
import Portal from 'shared/components/Portal'
import { dayNames } from 'shared/constants'

import { InputProps } from '../Input/types'

import styles from './DatePicker.module.css'

type DatePickerType = Omit<InputProps, 'onChange'> & {
  date?: string
  placeholder?: string
  closeOnChange?: boolean
  className?: string
  required?: boolean
  error?: boolean
  name?: string
  onChange(day: string): void
  onBlur?(): void
}

const DatePicker: React.FC<DatePickerType> = ({
  onChange,
  onBlur,
  placeholder,
  className,
  closeOnChange = true,
  required,
  date: initialDate,
  name,
  error,
  ...rest
}): React.ReactElement => {
  const [date, setDate] = useState<Date>(new Date())
  const [inputValue, setInputValue] = useState<string>('')
  const [openCalendar, toggleCalendar] = useState<boolean>(false)

  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)

  const monthName: string = useMemo((): string => format(date, 'LLLL', { locale: ru }), [date])
  const year = useMemo((): string => format(date, 'yyyy', { locale: ru }), [date])

  const matrix: CalendarMatrix = useMemo((): CalendarMatrix => {
    return calendarMatrix({ year: getYear(date), month: getMonth(date) })
  }, [date])

  const { styles: stylesPopper, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
  })

  useEffect(() => {
    if (initialDate) {
      const inputValue: string = format(new Date(initialDate), 'dd LL yyyy', { locale: ru })

      setDate(new Date(initialDate))
      setInputValue(inputValue)
    }
    return () => {
      setInputValue('')
      setDate(new Date())
    }
  }, [initialDate])

  const handleClose = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
      if (referenceElement && !referenceElement.contains(event.target as Node)) {
        toggleCalendar(false)
      }
      if (onBlur) {
        onBlur()
      }
    },
    [referenceElement],
  )

  const handleFocus = useCallback((): void => {
    toggleCalendar(!openCalendar)
  }, [openCalendar])

  // TODO: Unused function

  // const onChangeInput = useCallback(
  //   (event: React.ChangeEvent<HTMLInputElement>) => {
  //     const { value } = event.target
  //
  //     setInputValue(value)
  //
  //     const matchNumber = value.match(/\d/g) as Array<string>
  //
  //     if (!matchNumber) {
  //       setInputValue('')
  //       onChange('')
  //     }
  //
  //     if (matchNumber?.length === 8) {
  //       const dateArray = value.split(' ').map(Number)
  //       const newDate = new Date(dateArray[2], dateArray[1] - 1, dateArray[0])
  //
  //       onChange(formatISO(newDate))
  //
  //       setDate(newDate)
  //     }
  //   },
  //   [date, onChange],
  // )

  const handleClickDay = useCallback(
    (date: Date) => (_: React.MouseEvent<HTMLElement>): void => {
      const newDate: string = format(date, 'dd LL yyyy', { locale: ru })

      setDate(date)
      setInputValue(newDate)

      onChange(formatISO(date))

      if (closeOnChange) {
        toggleCalendar(false)
      }
    },
    [closeOnChange, onChange],
  )

  const getNextMonth = useCallback(
    (value: number) => (event: React.MouseEvent<HTMLElement>): void => {
      event.preventDefault()

      const newDate: Date = addMonths(date, value)
      const newDateValue: string = format(newDate, 'dd LL yyyy', { locale: ru })

      setDate(newDate)
      setInputValue(newDateValue)
      onChange(formatISO(newDate))
    },
    [date, onChange],
  )

  const getNextYear = useCallback(
    (value: number) => (event: React.MouseEvent<HTMLElement>): void => {
      event.preventDefault()

      const newDate: Date = addYears(date, value)
      const newDateValue: string = format(newDate, 'dd LL yyyy', { locale: ru })

      setDate(newDate)
      setInputValue(newDateValue)
      onChange(formatISO(newDate))
    },
    [date, onChange],
  )

  return (
    <>
      <InputMask readOnly value={inputValue} mask="99.99.9999">
        <Input
          placeholder={placeholder}
          name={name}
          ref={setReferenceElement}
          onClick={handleFocus}
          onIconRightClick={handleFocus}
          className={className}
          iconRight={CalendarIcon}
          error={error}
          required={required}
          {...rest}
        />
      </InputMask>

      {openCalendar && (
        <Portal>
          <OutsideClickHandler onOutsideClick={handleClose}>
            <div className={styles.calendar} ref={setPopperElement} style={stylesPopper.popper} {...attributes.popper}>
              <div className={cn(styles.calendar__row, styles.calendar__head)}>
                {dayNames.map((day: string) => (
                  <Typography key={day} color="brand" size="m" className={styles.calendar__cell}>
                    {day}
                  </Typography>
                ))}
              </div>

              {matrix.map((weak: Array<Date>) => (
                <div key={nanoid()} className={styles.calendar__row}>
                  {weak.map((day: Date) => {
                    if (!isSameMonth(day, date)) {
                      return <div key={nanoid()}>{null}</div>
                    }

                    return (
                      <Typography
                        component="div"
                        size="xs"
                        key={nanoid()}
                        className={cn(styles.calendar__cell, styles.calendar__cellDay, {
                          [styles.calendar__cellDay_selected]: day.valueOf() === date.valueOf(),
                        })}
                        onClick={handleClickDay(day)}
                      >
                        {format(day, 'd')}
                      </Typography>
                    )
                  })}
                </div>
              ))}

              <div className={styles.calendar__changeWrapper}>
                <div className={styles.calendar__changeContainer}>
                  <ButtonIcon onClick={getNextMonth(-1)}>
                    <Arrow />
                  </ButtonIcon>
                  <Typography component="span" className={styles.calendar__dateName}>
                    {monthName}
                  </Typography>
                  <ButtonIcon onClick={getNextMonth(1)}>
                    <Arrow className={styles.calendar__arrow} />
                  </ButtonIcon>
                </div>

                <div className={styles.calendar__changeContainer}>
                  <ButtonIcon onClick={getNextYear(-1)}>
                    <Arrow />
                  </ButtonIcon>
                  <Typography component="span" className={styles.calendar__dateName}>
                    {year}
                  </Typography>
                  <ButtonIcon onClick={getNextYear(1)}>
                    <Arrow className={styles.calendar__arrow} />
                  </ButtonIcon>
                </div>
              </div>
            </div>
          </OutsideClickHandler>
        </Portal>
      )}
    </>
  )
}

export default DatePicker
