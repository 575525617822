import React from 'react'
import { GoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login'

import { LoginResponse } from 'shared/features/Auth/typings'
import AuthRepository from 'shared/features/Auth/AuthRepository'
import { SocialNetwork } from 'shared/types'

import styles from './GoogleWidget.module.css'

const isGoogleLoginResponse = (obj: GoogleLoginResponse | GoogleLoginResponseOffline): obj is GoogleLoginResponse => {
  return 'tokenId' in obj
}

interface GoogleWidgetProps {
  setAuthTokens(tokens: LoginResponse): void
}

const GoogleWidget: React.FC<GoogleWidgetProps> = ({ setAuthTokens }): React.ReactElement => {
  const handleGoogleResponse = async (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    if (isGoogleLoginResponse(response)) {
      const { tokenId } = response
      const tokens: LoginResponse = await AuthRepository.loginWithSocialNetWork(
        {
          token_id: tokenId,
        },
        SocialNetwork.GOOGLE,
      )
      setAuthTokens(tokens)
    }
  }

  return <GoogleLogin clientId={GOOGLE_APP_AUTH_ID} onSuccess={handleGoogleResponse} className={styles.button} />
}
export default GoogleWidget
