import { AppState } from 'shared/store/reducer'

export const getAuthIsLoading = (state: AppState): boolean => state.auth.isLoading
export const getLoginIsLoading = (state: AppState): boolean => state.auth.loginIsloading
export const getActivationIsLoading = (state: AppState): boolean => state.auth.activationIsloading
export const getReactivationIsLoading = (state: AppState): boolean => state.auth.reactivationIsloading
export const getRegistrationIsLoading = (state: AppState): boolean => state.auth.registrationIsloading
export const getRegistrationEmail = (state: AppState): string | null => state.auth.registrationEmail
export const getIsReactivated = (state: AppState): boolean => state.auth.isReactivated
export const getIsAvailableEmail = (state: AppState): boolean => state.auth.isAvailableEmail
export const getIsAvailableLogin = (state: AppState): boolean => state.auth.isAvailableLogin
export const getIsNewPassword = (state: AppState): boolean => state.auth.isNewPassword
