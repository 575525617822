import React from 'react'

import Typography from 'shared/components/Typography'

import { AuthForm } from '../AuthForm'
import { AuthSidebar } from '../AuthSidebar'

import styles from './AuthWrapper.module.css'

interface Props {
  title: string
  sidebar: React.ReactElement
}

const AuthWrapper: React.FC<Props> = ({ title, sidebar, children }): React.ReactElement => (
  <div className={styles.container}>
    <Typography className={styles.title} size="l" weight="bold" uppercase component="h1">
      {title}
    </Typography>
    <AuthSidebar className={styles.sidebar}>{sidebar}</AuthSidebar>
    <AuthForm className={styles.form}>{children}</AuthForm>
  </div>
)

export default AuthWrapper
