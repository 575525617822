import React from 'react'

import Button from 'shared/components/Button'

import { AuthSidebarHints } from '../AuthSidebarHints'

type Props = {
  isResendAvailable: boolean
  onResend: () => void
}

const textBeforeRecovery = 'Введите адрес почты и далее следуйте инструкциям которые получите'
const textAfterRecovery = 'Если письмо для сброса пароля не пришло на ваш e-mail, вы можете отправить его повторно.'

export const PasswordRecoverySidebar: React.FC<Props> = ({ isResendAvailable, onResend }) => {
  const hint = isResendAvailable ? textAfterRecovery : textBeforeRecovery

  return (
    <>
      <AuthSidebarHints hints={[hint]} />
      {isResendAvailable && (
        <Button onClick={onResend} fullWidth>
          Отправить повторно
        </Button>
      )}
    </>
  )
}
