import * as yup from 'yup'

import { NewPasswordFormPayload } from 'shared/features/Auth/typings'

export default yup.object<NewPasswordFormPayload>().shape<NewPasswordFormPayload>({
  password: yup
    .string()
    .min(6, 'Минимальная длина 6 символов')
    .matches(/^[A-z\d]+$/, 'Буквы (только латинские) и/или цифры')
    .max(20, 'Максимальная длина 20 сиимволов')
    .required('Пароль обязателен для заполнения'),
  passwordCompare: yup
    .string()
    .required('Пароль обязателен для заполнения')
    .oneOf([yup.ref('password'), ''], 'Пароли не совпадают'),
})
