import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { login as loginRoute } from 'shared/constants/routes'
import { getTokenQuery } from 'shared/core/ducks/selectors'

import { actions } from '../ducks'

export default () => {
  const dispatch = useDispatch()
  const { push } = useHistory()

  const token = useSelector(getTokenQuery)

  useEffect(() => {
    if (!token) {
      return
    }

    dispatch(actions.activation(token))
    push(loginRoute.path)
  }, [])
}
