import React from 'react'
import { FormikProps } from 'formik'

import { ReactComponent as SendMailIcon } from 'shared/assets/icons/send_mail.svg'
import FormItem from 'shared/components/FormItem'
import Input from 'shared/components/Input'
import { PasswordRecoverPayload } from 'shared/features/Auth/typings'
import ButtonIcon from 'shared/components/ButtonIcon'
import { ReactComponent as ForwardIcon } from 'shared/assets/icons/login.svg'
import Typography from 'shared/components/Typography'

import styles from './PasswordRecoveryForm.module.css'

const isSentText = (email: string) => {
  return `На вашу почту ${email} отправлено письмо со ссылкой для смены пароля`
}
const isResentText = (email: string) => {
  return `Письмо со ссылкой для смены пароля повторно отправлено на вашу почту ${email}`
}

interface PasswordRecoveryFormProps extends FormikProps<PasswordRecoverPayload> {
  isLoading: boolean
  isSent: boolean
  isResent: boolean
}

const PasswordRecoveryForm: React.FC<PasswordRecoveryFormProps> = ({
  values,
  errors,
  touched,
  handleChange,
  handleSubmit,
  handleBlur,
  isValid,
  isLoading,
  isSent,
  isResent,
}): React.ReactElement => {
  const { email } = values
  const { email: emailError } = errors

  const isEmailError = Boolean(emailError && touched.email)

  const isSentMessage = isResent ? isResentText(email) : isSentText(email)

  return (
    <>
      {isSent ? (
        <div className={styles.mailSent}>
          <SendMailIcon className={styles.mailSent__icon} />
          <Typography>Проверьте почту</Typography>
          <Typography className={styles.mailSent__label}>{isSentMessage}</Typography>
        </div>
      ) : (
        <form className={styles.form} onSubmit={handleSubmit}>
          <FormItem label="E-mail" error={isEmailError} errorText={emailError}>
            <Input
              value={email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={isEmailError}
              name="email"
              required
              fullWidth
            />
          </FormItem>

          <div className={styles.form__actions}>
            <ButtonIcon
              type="submit"
              classNameLabel={styles.form__loginLabel}
              label="Отправить"
              disabled={!isValid || isLoading || !email}
            >
              <ForwardIcon />
            </ButtonIcon>
          </div>
        </form>
      )}
    </>
  )
}

export default PasswordRecoveryForm
