/* eslint-disable import/no-duplicates */
import { getMonth, addDays, startOfWeek } from 'date-fns'
import { ru } from 'date-fns/locale'
import { addIndex, map, repeat, splitEvery } from 'ramda'

type MatrixFabricParams = {
  year: number
  month: number
  split?: number
}

export type CalendarMatrix = Array<Array<Date>>

const calendarMatrix = ({ year, month, split }: MatrixFabricParams): CalendarMatrix =>
  splitEvery(
    split || 7,
    addIndex<Date>(map)(addDays, repeat<Date>(startOfWeek(new Date(year, month), { locale: ru }), 42)),
  ).filter((row: Array<Date>) => !row.every((day: Date) => month !== getMonth(day)))

export default calendarMatrix
