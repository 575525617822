import React from 'react'

import Typography from 'shared/components/Typography'

import styles from './AuthSidebarHints.module.css'

type Props = {
  className?: string
  hints: string[]
}

export const AuthSidebarHints: React.FC<Props> = ({ hints }) => {
  return (
    <div className={styles.hints}>
      {hints.map(hint => (
        <Typography key={hint} size="xs" spacy weight="light">
          {hint}
        </Typography>
      ))}
    </div>
  )
}
