import React, { useMemo } from 'react'
import { FormikProps } from 'formik'

import FormItem from 'shared/components/FormItem'
import Input from 'shared/components/Input'
import { NewPasswordFormPayload } from 'shared/features/Auth/typings'
import ButtonIcon from 'shared/components/ButtonIcon'
import { ReactComponent as ForwardIcon } from 'shared/assets/icons/forward_arrow.svg'

import styles from './NewPasswordForm.module.css'

interface NewPasswordFormProps extends FormikProps<NewPasswordFormPayload> {
  isLoading?: boolean
}

const NewPasswordForm: React.FC<NewPasswordFormProps> = ({
  values,
  errors,
  touched,
  handleChange,
  handleSubmit,
  handleBlur,
  isValid,
  isLoading = false,
}): React.ReactElement => {
  const { password, passwordCompare } = values

  const { password: passwordError, passwordCompare: passwordCompareError } = errors

  const isPasswordError = Boolean(passwordError && touched.password)
  const isPasswordCompareError = Boolean(passwordCompareError && touched.passwordCompare)

  const isDisabledSubmit = useMemo((): boolean => {
    return !isValid || isLoading || !password || !passwordCompare
  }, [isValid, isLoading, password, passwordCompare])

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <FormItem label="Пароль" error={isPasswordError} errorText={passwordError} className={styles.form__item}>
          <Input
            value={password}
            onChange={handleChange}
            onBlur={handleBlur}
            error={isPasswordError}
            name="password"
            type="password"
            required
            fullWidth
          />
        </FormItem>

        <FormItem
          label="Пароль еще раз"
          error={isPasswordCompareError}
          errorText={passwordCompareError}
          className={styles.form__item}
        >
          <Input
            value={passwordCompare}
            onChange={handleChange}
            onBlur={handleBlur}
            error={isPasswordCompareError}
            name="passwordCompare"
            type="password"
            required
            fullWidth
          />
        </FormItem>
      </div>

      <div className={styles.form__actions}>
        <ButtonIcon
          type="submit"
          classNameLabel={styles.form__loginLabel}
          disabled={isDisabledSubmit}
          label="Сохранить"
        >
          <ForwardIcon />
        </ButtonIcon>
      </div>
    </form>
  )
}

export default NewPasswordForm
