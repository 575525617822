import React from 'react'

import { ButtonIconLinkProps } from 'shared/components/ButtonIconLink/ButtonIconLink'
import Typography from 'shared/components/Typography'

import styles from './AuthSidebarActionCard.module.css'

type Props = {
  text: string
  button?: React.ReactElement<ButtonIconLinkProps>
}

export const AuthSidebarActionCard: React.FC<Props> = ({ text, button }) => {
  return (
    <div className={styles.card}>
      <Typography size="xs" spacy weight="light">
        {text}
      </Typography>
      {button}
    </div>
  )
}
