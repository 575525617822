import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { Formik, FormikProps } from 'formik'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'

import AuthLayout from 'shared/core/layouts/AuthLayout'
import AuthWrapper from 'shared/features/Auth/components/AuthWrapper'
import { NewPasswordFormPayload } from 'shared/features/Auth/typings'
import NewPasswordForm, { validationSchema } from 'shared/features/Auth/components/NewPasswordForm'
import { home as homeRoute, login as loginRoute } from 'shared/constants/routes'
import { getTokenQuery } from 'shared/core/ducks/selectors'

import { AuthTitle } from '../../constants'
import { NewPasswordSidebar } from '../../components/NewPasswordSidebar'
import { actions } from '../../ducks'
import { getAuthIsLoading, getIsNewPassword } from '../../ducks/selectors'
import { AuthLoader } from '../../components/AuthLoader'

const initialValues: NewPasswordFormPayload = {
  password: '',
  passwordCompare: '',
}

const NewPassword: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch()
  const { push } = useHistory()

  const token = useSelector(getTokenQuery)
  const isNewPassword = useSelector(getIsNewPassword)
  const isLoading = useSelector(getAuthIsLoading)

  useEffect(() => {
    if (!token) {
      push(homeRoute.path)
    }

    if (isNewPassword) {
      push(loginRoute.path)
    }
  }, [isNewPassword])

  const handleSubmit = ({ password }: NewPasswordFormPayload) => {
    if (!token) return

    dispatch(
      actions.newPassword({
        password,
        token,
      }),
    )
  }

  return (
    <>
      <Helmet>
        <title>{AuthTitle.NewPassword}</title>
        <script type="text/javascript" src="https://vk.com/js/api/openapi.js?169" />
        <meta name="auth" content={AuthTitle.NewPassword} />
        <meta name="description" content={`${AuthTitle.NewPassword} FuttyCoin`} />
      </Helmet>

      <AuthLayout>
        <AuthWrapper title={AuthTitle.NewPassword} sidebar={<NewPasswordSidebar />}>
          {isLoading && <AuthLoader status="Обновление пароля..." />}
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
            {(props: FormikProps<NewPasswordFormPayload>) => (
              <NewPasswordForm {...props} isLoading={isLoading} validateOnMount />
            )}
          </Formik>
        </AuthWrapper>
      </AuthLayout>
    </>
  )
}

export default NewPassword
