import React, { useMemo, useState } from 'react'
import { FormikProps } from 'formik'
import cn from 'clsx'

import FormItem from 'shared/components/FormItem'
import Input from 'shared/components/Input'
import { RegistrationFormPayload } from 'shared/features/Auth/typings'
import ButtonIcon from 'shared/components/ButtonIcon'
import Select from 'shared/components/Select'
import { Platform, platforms } from 'shared/constants'
import { SelectOption } from 'shared/components/interfaces'
import { ReactComponent as UserIcon } from 'shared/assets/icons/login.svg'
import DatePicker from 'shared/components/DatePicker'
import Checkbox from 'shared/components/Checkbox'
import { CDN_REGISTRATION } from 'shared/constants/downloadFiles'

import styles from './RegistrationForm.module.css'

interface RegistrationFormProps extends FormikProps<RegistrationFormPayload> {
  isLoading: boolean
  isAvailableLogin: boolean
  isAvailableEmail: boolean
}

const RegistrationForm: React.FC<RegistrationFormProps> = (props): React.ReactElement => {
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleBlur,
    isValid,
    isLoading,
    isAvailableEmail,
    isAvailableLogin,
    setFieldValue,
    setFieldTouched,
    touched,
  } = props
  const { email, login, birthday, password, passwordCompare, platform } = values

  const {
    birthday: birthdayError,
    email: emailError,
    login: loginError,
    password: passwordError,
    passwordCompare: passwordCompareError,
    platform: platformError,
  } = errors

  const [userAgreed, setUserAgreed] = useState<boolean>(false)

  const isDisableSubmit = useMemo((): boolean => {
    return !isValid || isLoading || !userAgreed
  }, [isLoading, isValid, userAgreed])

  const isEmailError = Boolean(emailError && touched.email) || !isAvailableEmail
  const isLoginError = Boolean(loginError && touched.login) || !isAvailableLogin
  const isPasswordError = Boolean(passwordError && touched.password)
  const isPasswordCompareError = Boolean(passwordCompareError && touched.passwordCompare)
  const isPlatformError = Boolean(platformError && touched.platform)
  const isBirthdayError = Boolean(birthdayError && touched.birthday)

  const handleBlurDay = () => {
    setFieldTouched('birthday', true)
  }

  const handleChangeDay = (value: string): void => {
    setFieldValue('birthday', value, true)
  }

  const handleChangePlatform = (value: SelectOption<Platform> | null): void => {
    setFieldTouched('platform', true)
    setFieldValue('platform', value, true)
  }

  const handleChangeAgreement = (): void => {
    setUserAgreed(!userAgreed)
  }

  const agreementComponent = (
    <div className={styles.agreement}>
      <Checkbox onChange={handleChangeAgreement} checked={userAgreed} />
      <div className={styles.agreement__content}>
        Я принимаю условия&nbsp;
        <a
          href={`${CDN_REGISTRATION}/agreement/agreement.pdf`}
          className={styles.agreement__link}
          download="Пользовательское соглашение"
        >
          Пользовательского соглашения
        </a>
        &nbsp;и даю свое согласие порталу FuttyCoin на обработку моей персональной информации на условиях,
        определенных&nbsp;
        <a
          href={`${CDN_REGISTRATION}/confidentiality/confidentiality.pdf`}
          className={styles.agreement__link}
          download="Политика конфиденциальности"
        >
          Политикой конфиденциальности
        </a>
      </div>
    </div>
  )

  return (
    <form onSubmit={handleSubmit}>
      <FormItem
        label="E-mail"
        error={isEmailError}
        errorText={!isAvailableEmail ? 'email уже используется' : emailError}
        className={styles.form__item}
      >
        <Input
          value={email}
          onChange={handleChange}
          onBlur={handleBlur}
          error={isEmailError}
          name="email"
          size="s"
          required
          fullWidth
        />
      </FormItem>

      <div className={styles.row}>
        <FormItem label="Дата рождения" error={isBirthdayError} errorText={birthdayError} className={styles.form__item}>
          <DatePicker
            className={cn(styles.row__input, styles.form__input)}
            onChange={handleChangeDay}
            onBlur={handleBlurDay}
            date={birthday}
            placeholder="дд / мм / гг"
            size="s"
            error={isBirthdayError}
            name="birthday"
            required
          />
        </FormItem>

        <FormItem label="Консоль" error={isPlatformError} className={styles.form__item}>
          <Select
            name="platform"
            classNameContainer={styles.form__platformSelect}
            classNameIcon={styles.form__platformSelecIicon}
            value={platform}
            options={platforms}
            onChange={handleChangePlatform}
            isSearchable={false}
            isClearable={false}
          />
        </FormItem>
      </div>

      <FormItem
        label="Имя пользователя"
        error={isLoginError}
        errorText={!isAvailableLogin ? 'логин уже используется' : loginError}
        className={styles.form__item}
      >
        <Input
          value={login}
          onChange={handleChange}
          onBlur={handleBlur}
          error={isLoginError}
          name="login"
          size="s"
          required
          fullWidth
        />
      </FormItem>

      <div className={styles.row}>
        <FormItem label="Пароль" error={isPasswordError} errorText={passwordError} className={styles.form__item}>
          <Input
            className={styles.row__input}
            value={password}
            onChange={handleChange}
            onBlur={handleBlur}
            error={isPasswordError}
            name="password"
            type="password"
            size="s"
            required
            fullWidth
          />
        </FormItem>

        <FormItem
          label="Пароль еще раз"
          error={isPasswordCompareError}
          errorText={passwordCompareError}
          className={styles.form__item}
        >
          <Input
            className={styles.row__input}
            value={passwordCompare}
            onChange={handleChange}
            onBlur={handleBlur}
            error={isPasswordCompareError}
            name="passwordCompare"
            type="password"
            size="s"
            required
            fullWidth
          />
        </FormItem>
      </div>

      {agreementComponent}

      <div className={styles.actions}>
        <ButtonIcon
          type="submit"
          classNameLabel={styles.actions__loginLabel}
          disabled={isDisableSubmit}
          label="Создать аккаунт"
        >
          <UserIcon />
        </ButtonIcon>
      </div>
    </form>
  )
}

export default RegistrationForm
