import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { getAuthorized } from 'shared/features/Profile/ducks/selectors'
import { getRedirectPath } from 'shared/core/ducks/selectors'

export default () => {
  const isAuthorized = useSelector(getAuthorized)
  const redirectPath = useSelector(getRedirectPath)

  const { push } = useHistory()

  useEffect(() => {
    if (isAuthorized) {
      push(redirectPath)
    }
  }, [isAuthorized, redirectPath])
}
