import React from 'react'
import cn from 'clsx'

import styles from './AuthSidebar.module.css'

type AuthSidebarProps = {
  className?: string
}

export const AuthSidebar: React.FC<AuthSidebarProps> = ({ className, children }) => {
  return <div className={cn(className, styles.sidebar)}>{children}</div>
}
