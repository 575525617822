import React from 'react'

import { ReactComponent as UserIcon } from 'shared/assets/icons/user.svg'
import { ReactComponent as QuestionIcon } from 'shared/assets/icons/question.svg'
import ButtonIconLink from 'shared/components/ButtonIconLink'
import { registration as registrationRoute, passwordRecovery as passwordRecoveryRoute } from 'shared/constants/routes'

import { AuthWidgets } from '../AuthWidgets'
import { AuthSidebarActionCard } from '../AuthSidebarActionCard'

const accountCreate = (
  <ButtonIconLink to={registrationRoute.path} color="secondary" label="Создать аккаунт">
    <UserIcon />
  </ButtonIconLink>
)

const passwordRecovery = (
  <ButtonIconLink to={passwordRecoveryRoute.path} color="secondary" label="Восстановить пароль">
    <QuestionIcon />
  </ButtonIconLink>
)

export const LoginSidebar = () => {
  return (
    <>
      <AuthWidgets />
      <AuthSidebarActionCard text="У вас еще нет аккаунта на FuttyCoin?" button={accountCreate} />
      <AuthSidebarActionCard text="Забыли пароль? Используйте опцию восстановления" button={passwordRecovery} />
    </>
  )
}
