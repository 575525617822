import React, { useCallback } from 'react'
import { Formik, FormikProps } from 'formik'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'

import AuthLayout from 'shared/core/layouts/AuthLayout'
import AuthWrapper from 'shared/features/Auth/components/AuthWrapper'
import { RegistrationFormPayload } from 'shared/features/Auth/typings'
import RegistrationForm, { validationSchema } from 'shared/features/Auth/components/RegistrationForm'
import { platforms } from 'shared/constants'

import { AuthTitle } from '../../constants'
import { RegistrationSidebar } from '../../components/RegistrationSidebar'
import useRedirectFromLogin from '../../hooks/useRedirectFromLogin'
import { actions } from '../../ducks'
import {
  getIsAvailableEmail,
  getIsAvailableLogin,
  getIsReactivated,
  getReactivationIsLoading,
  getRegistrationEmail,
  getRegistrationIsLoading,
} from '../../ducks/selectors'
import { AuthInfo } from '../../components/AuthInfo/AuthInfo'
import { AuthLoader } from '../../components/AuthLoader'

const sendedText = 'На указаный вами E-mail отправлено письмо со ссылкой для активации аккаунта'
const reactivatedText = 'Письмо со ссылкой для активации аккаунта повторно отправлено на указанный вами E-mail'

const initialValues: RegistrationFormPayload = {
  email: '',
  login: '',
  birthday: '',
  platform: platforms[0],
  password: '',
  passwordCompare: '',
}

const Registration: React.FC = (): React.ReactElement => {
  useRedirectFromLogin()
  const dispatch = useDispatch()

  const registrationEmail = useSelector(getRegistrationEmail)
  const registrationIsLoading = useSelector(getRegistrationIsLoading)
  const reactivationIsLoading = useSelector(getReactivationIsLoading)
  const isReactivated = useSelector(getIsReactivated)
  const isAvailableEmail = useSelector(getIsAvailableEmail)
  const isAvailableLogin = useSelector(getIsAvailableLogin)

  const description = isReactivated ? reactivatedText : sendedText

  const handleSubmit = useCallback(
    ({ birthday, email, login, password, platform }: RegistrationFormPayload) => {
      dispatch(
        actions.registration({
          birthday,
          email,
          login,
          password,
          platform: platform.value,
        }),
      )
    },
    [dispatch],
  )

  return (
    <>
      <Helmet>
        <title>{AuthTitle.Registration}</title>
        <script type="text/javascript" src="https://vk.com/js/api/openapi.js?169" />
        <meta name="auth" content={AuthTitle.Registration} />
        <meta name="description" content={`${AuthTitle.Registration} FuttyCoin`} />
      </Helmet>
      <AuthLayout>
        <AuthWrapper
          title={AuthTitle.Registration}
          sidebar={<RegistrationSidebar registrationEmail={registrationEmail} />}
        >
          {registrationIsLoading && <AuthLoader status="Регистрация..." />}
          {reactivationIsLoading && <AuthLoader status="Повторная отправка..." />}
          {(registrationEmail || isReactivated) && <AuthInfo title="Проверьте почту" description={description} />}
          {!registrationEmail && !isReactivated && (
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
              {(props: FormikProps<RegistrationFormPayload>) => (
                <RegistrationForm
                  {...props}
                  isLoading={registrationIsLoading}
                  isAvailableEmail={isAvailableEmail}
                  isAvailableLogin={isAvailableLogin}
                />
              )}
            </Formik>
          )}
        </AuthWrapper>
      </AuthLayout>
    </>
  )
}

export default Registration
