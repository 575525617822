import React from 'react'
import cn from 'clsx'

import styles from './Menu.module.css'

interface MenuItemProps {
  selected?: boolean
  className?: string
  classNameIcon?: string
  classNameSelected?: string
  icon?: React.ReactNode
  onClick?(event: React.MouseEvent<HTMLElement, MouseEvent>): void
  onKeyDown?(event: React.KeyboardEvent<HTMLLIElement>): void
}

const MenuItem: React.FC<MenuItemProps> = ({
  selected,
  onClick,
  onKeyDown,
  className,
  classNameIcon,
  classNameSelected,
  children,
  icon: Icon,
}): React.ReactElement => (
  <li
    className={cn(
      styles.menu__item,
      {
        [styles.menu__item_selected]: selected,
      },
      className,
      selected ? classNameSelected : undefined,
    )}
    onClick={onClick}
    onKeyDown={onKeyDown}
    role="menuitem"
  >
    {Icon && <span className={cn(styles.menu__iconWrapper, classNameIcon)}>{Icon}</span>}
    {children}
  </li>
)

MenuItem.defaultProps = {
  selected: false,
}

export default MenuItem
