import * as yup from 'yup'

import { RegistrationFormPayload } from 'shared/features/Auth/typings'
import { SelectOption } from 'shared/components/interfaces'
import { Platform } from 'shared/constants'

export default yup.object<RegistrationFormPayload>().shape<RegistrationFormPayload>({
  email: yup.string().email('E-mail указан некорректно').required('E-mail обязателен для заполнения'),
  login: yup
    .string()
    .min(3, 'Минимальная длина 3 символа')
    .matches(/^[A-zА-яёЁ\s\d]+$/, 'Латинские или русские буквы и/или цифры')
    .max(50, 'Максимальная длина 50 сиимволов')
    .required('Имя пользователя обязательно для заполнения'),
  birthday: yup.string().required('Обязательно для заполнения'),
  password: yup
    .string()
    .min(6, 'Минимальная длина 6 символов')
    .matches(/^[A-z\d]+$/, 'Буквы (только латинские) и/или цифры')
    .max(20, 'Максимальная длина 20 сиимволов')
    .required('Пароль обязателен для заполнения'),
  passwordCompare: yup
    .string()
    .required('Пароль обязателен для заполнения')
    .oneOf([yup.ref('password'), ''], 'Пароли не совпадают'),
  platform: yup.object<SelectOption<Platform>>().required('Обязательно для заполнения'),
})
