import React from 'react'
import TelegramLoginButton, { TelegramUser } from 'telegram-login-button'

import { LoginResponse } from 'shared/features/Auth/typings'
import AuthRepository from 'shared/features/Auth/AuthRepository'
import { SocialNetwork } from 'shared/types'

interface TelegramWidgetProps {
  className?: string
  setAuthTokens(tokens: LoginResponse): void
}

const TelegramWidget: React.FC<TelegramWidgetProps> = ({ setAuthTokens, className }): React.ReactElement | null => {
  const handleTelegramResponse = async (response: TelegramUser) => {
    const tokens: LoginResponse = await AuthRepository.loginWithSocialNetWork(response, SocialNetwork.TELEGRAM)
    setAuthTokens(tokens)
  }

  if (TG_BOT_NAME) {
    return (
      <TelegramLoginButton
        dataOnauth={handleTelegramResponse}
        cornerRadius={5}
        botName={TG_BOT_NAME}
        className={className}
      />
    )
  }
  return null
}
export default TelegramWidget
