import React from 'react'

import Banner from 'shared/components/Banner/Banner'
import { adsAuthLayoutLeft, adsAuthLayoutRight } from 'shared/constants/ads'
import { banner240x600 } from 'shared/constants/bannerSizes'

import { BaseLayout } from '../BaseLayout/BaseLayout'

import styles from './AuthLayout.module.css'

const AuthLayout: React.FC = ({ children }): React.ReactElement => (
  <BaseLayout>
    <div className={styles.content}>
      <Banner className={styles.banner} {...banner240x600} {...adsAuthLayoutLeft} />
      {children}
      <Banner className={styles.banner} {...banner240x600} {...adsAuthLayoutRight} />
    </div>
  </BaseLayout>
)

export default AuthLayout
