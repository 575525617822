import React, { memo, useMemo } from 'react'
import cn from 'clsx'

import CheckboxBase, { CheckboxBaseProps, checkboxBaseDefaultProps } from 'shared/components/CheckboxBase'
import { ReactComponent as CheckIcon } from 'shared/assets/icons/check.svg'

import styles from './Checkbox.module.css'

interface CheckboxProps extends Omit<CheckboxBaseProps, 'component' | 'onChange'> {
  icon?: React.FC<React.SVGProps<SVGSVGElement>> | null
  classNameCheckbox?: string
  classNameIcon?: string
  classNameLabel?: string
  classNameInput?: string
  checkedStyleLabel?: boolean
  classNameCheckboxChecked?: string
  onChange?(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void
}

const Checkbox: React.FC<CheckboxProps> = (props): React.ReactElement => {
  const {
    icon: Icon = CheckIcon,
    classNameCheckbox,
    classNameIcon,
    classNameLabel,
    classNameInput,
    checkedStyleLabel,
    classNameCheckboxChecked,
    id,
    onChange,
    label,
    ...ownProps
  } = props

  const title: string | undefined = useMemo(() => {
    return typeof label === 'string' ? label : undefined
  }, [label])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (onChange) {
      onChange(event, event.target.checked)
    }
  }

  return (
    <CheckboxBase label={label} {...ownProps}>
      {({
        name,
        type,
        value,
        label,
        checked,
        disabled,
        block,
        testId,
        tabIndex,
        className,
      }: CheckboxBaseProps): React.ReactElement => (
        <label
          className={cn(
            styles.checkbox,
            {
              [styles.checkbox__block]: block,
              [styles.checkbox_disabled]: disabled,
              [styles.checkbox_checked]: checked && checkedStyleLabel,
            },
            className,
          )}
          htmlFor={id}
          data-test={testId}
          tabIndex={tabIndex}
          title={title}
        >
          <input
            type={type}
            id={id}
            className={cn(styles.checkbox__input, classNameInput)}
            name={name}
            value={value}
            checked={checked}
            disabled={disabled}
            onChange={handleChange}
          />
          <div
            className={cn(
              styles.checkbox__circle,
              {
                [cn(styles.checkbox__circle_checked, classNameCheckboxChecked)]: checked,
                [styles.checkbox__circle_disabled]: disabled,
              },
              classNameCheckbox,
            )}
          >
            <span className={cn(styles.checkbox__icon, classNameIcon)}>{Icon && <Icon />}</span>
          </div>
          <div className={cn(styles.checkbox__label, classNameLabel)}>{label}</div>
        </label>
      )}
    </CheckboxBase>
  )
}

Checkbox.defaultProps = {
  ...checkboxBaseDefaultProps,
  icon: CheckIcon,
}

export default memo(Checkbox)
