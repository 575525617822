import React from 'react'
import { useDispatch } from 'react-redux'

import Button from 'shared/components/Button'

import { actions } from '../../ducks'
import { AuthSidebarHints } from '../AuthSidebarHints'

const loginText = 'Имя пользователя: от 3 до 50 символов, латинские или русские буквы и/или цифры'
const passwordText = 'Пароль: от 6 до 20 символов, буквы (только латинские) и/или цифры'
const sentText = 'Если письмо для активации аккаунта не пришло на ваш e-mail, вы можете отправить его повторно'

type Props = {
  registrationEmail: string | null
}

export const RegistrationSidebar: React.FC<Props> = ({ registrationEmail }) => {
  const dispatch = useDispatch()

  const hints = registrationEmail ? [sentText] : [loginText, passwordText]

  const reactivation = (email: string) => () => dispatch(actions.reactivation({ email }))

  return (
    <>
      <AuthSidebarHints hints={hints} />
      {registrationEmail && (
        <Button onClick={reactivation(registrationEmail)} fullWidth>
          Отправить повторно
        </Button>
      )}
    </>
  )
}
