import React, { useMemo } from 'react'
import { FormikProps } from 'formik'

import FormItem from 'shared/components/FormItem'
import Input from 'shared/components/Input'
import { LoginPayload } from 'shared/features/Auth/typings'
import ButtonIcon from 'shared/components/ButtonIcon'
import Typography from 'shared/components/Typography'
import { ReactComponent as ArrowIcon } from 'shared/assets/icons/login.svg'
import Checkbox from 'shared/components/Checkbox'

import styles from './LoginForm.module.css'

interface LoginFormProps extends FormikProps<LoginPayload> {
  isLoading?: boolean
}

const LoginForm: React.FC<LoginFormProps> = ({
  values,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  isValid,
  isLoading = false,
  touched,
}): React.ReactElement => {
  const isDisabledSubmit = useMemo((): boolean => {
    return !isValid || isLoading
  }, [isValid, isLoading])

  const { username, password } = values

  const { password: passwordError, username: usernameError } = errors

  const isUsernameError = Boolean(usernameError && touched.username)
  const isPasswordError = Boolean(passwordError && touched.password)

  return (
    <form onSubmit={handleSubmit}>
      <FormItem
        label="имя пользователя"
        error={isUsernameError}
        errorText={usernameError}
        className={styles.form__item}
      >
        <Input
          value={username}
          onChange={handleChange}
          onBlur={handleBlur}
          error={isUsernameError}
          name="username"
          required
          fullWidth
        />
      </FormItem>

      <FormItem label="Пароль" error={isPasswordError} errorText={passwordError} className={styles.form__item}>
        <Input
          value={password}
          onChange={handleChange}
          onBlur={handleBlur}
          error={isPasswordError}
          name="password"
          type="password"
          required
          fullWidth
        />
      </FormItem>

      <div className={styles.form__actions}>
        <div className={styles.form__remember}>
          <Checkbox />
          <Typography component="span" className={styles.form__remember}>
            Запомнить
          </Typography>
        </div>
        <ButtonIcon
          className={styles.form__actionsBtn}
          disabled={isDisabledSubmit}
          type="submit"
          label="Войти"
          color="inherit"
        >
          <ArrowIcon />
        </ButtonIcon>
      </div>
    </form>
  )
}

export default LoginForm
