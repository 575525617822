import React from 'react'
import { useDispatch } from 'react-redux'

import { actions as actionsProfile } from 'shared/features/Profile/ducks'

import AuthService from '../../AuthService'
import { LoginResponse } from '../../typings'
import GoogleWidget from '../GoogleWidget'
import TelegramWidget from '../TelegramWidget'
import VKWidget from '../VKWidget'
import YandexWidget from '../YandexWidget'

import styles from './AuthWidgets.module.css'

export const AuthWidgets = () => {
  const dispatch = useDispatch()

  const setAuthTokens = (tokens: LoginResponse) => {
    AuthService.setToken(tokens)
    dispatch(actionsProfile.getAccount())
  }

  return (
    <div className={styles.widgets}>
      <VKWidget setAuthTokens={setAuthTokens} />
      <YandexWidget setAuthTokens={setAuthTokens} />
      <GoogleWidget setAuthTokens={setAuthTokens} />
      <TelegramWidget setAuthTokens={setAuthTokens} />
    </div>
  )
}
