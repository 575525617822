import React, { useEffect, useState } from 'react'

import Dialog from 'shared/components/Dialog'
import styles from 'shared/features/Auth/components/RecoveryDialog/RecoveryDialog.module.css'
import { ReactComponent as LetterIcon } from 'shared/assets/icons/mail_send_1.svg'
import Typography from 'shared/components/Typography'

interface RecoveryDialog {
  open?: boolean
  duration?: number
}
const RecoveryDialog: React.FC<RecoveryDialog> = ({ open = true, duration = 2000 }): React.ReactElement => {
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null)
  const [openDialog, setOpenDialog] = useState(open)

  useEffect(() => {
    if (openDialog) {
      const timeout = setTimeout(() => setOpenDialog(false), duration)
      setTimeoutId(timeout)
    }
  }, [openDialog, setOpenDialog, duration])

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [timeoutId])

  return (
    <Dialog open={openDialog} classNameModal={styles.dialog}>
      <LetterIcon className={styles.dialog__icon} />
      <Typography className={styles.dialog__text}>
        Отправка нового письма для восстановления пароля будет недоступна
      </Typography>
    </Dialog>
  )
}
export default RecoveryDialog
