import React, { memo } from 'react'

import { ReactComponent as YandexIcon } from 'shared/assets/icons/yandex_icon.svg'
import { SocialNetwork } from 'shared/types'

import AuthRepository from '../../AuthRepository'
import { LoginResponse, YandexLoginResponse } from '../../typings'

import styles from './YandexWidget.module.css'
import YandexLogin from './YandexLogin'

interface YandexWidgetProps {
  setAuthTokens(tokens: LoginResponse): void
}

const YandexWidget: React.FC<YandexWidgetProps> = ({ setAuthTokens }): React.ReactElement => {
  const isPageLoaded = typeof window !== 'undefined'

  const handleYandexResponse = async (response: YandexLoginResponse) => {
    const { access_token } = response
    if (access_token) {
      const tokens = await AuthRepository.loginWithSocialNetWork(
        {
          oauth_token: access_token,
        },
        SocialNetwork.YANDEX,
      )
      setAuthTokens(tokens)
    }
  }

  return (
    <>
      {isPageLoaded && (
        <YandexLogin clientID={YANDEX_CLIENT_ID} onSuccess={handleYandexResponse}>
          <button className={styles.button}>
            <YandexIcon className={styles.icon} />
            Войти с Яндекс ID
          </button>
        </YandexLogin>
      )}
    </>
  )
}
export default memo(YandexWidget)
