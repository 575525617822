import React, { useState } from 'react'
import { Formik, FormikProps } from 'formik'
import { Helmet } from 'react-helmet-async'

import AuthLayout from 'shared/core/layouts/AuthLayout'
import AuthWrapper from 'shared/features/Auth/components/AuthWrapper'
import { PasswordRecoverPayload } from 'shared/features/Auth/typings'
import PasswordRecoveryForm, { validationSchema } from 'shared/features/Auth/components/PasswordRecoveryForm'
import AuthRepository from 'shared/features/Auth/AuthRepository'
import DialogStatus from 'shared/core/components/DialogStatus'
import RecoveryDialog from 'shared/features/Auth/components/RecoveryDialog'

import { AuthTitle } from '../../constants'
import { PasswordRecoverySidebar } from '../../components/PasswordRecoverySidebar'

const PasswordRecovery: React.FC = (): React.ReactElement => {
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isSent, setSent] = useState<boolean>(false)
  const [isResent, setResent] = useState<boolean>(false)
  const [stateEmail, setStateEmail] = useState<string>('')
  const [values] = useState<PasswordRecoverPayload>({
    email: '',
  })
  const [showDialogStatus, setShowDialogStatus] = useState<boolean>(false)
  const [isEmailAvailable, setEmailAvailable] = useState<boolean>(true)

  const handleShowDialogStatus = () => setShowDialogStatus(true)
  const handleHideDialogStatus = () => setShowDialogStatus(false)

  const handleSubmit = async ({ email }: PasswordRecoverPayload): Promise<void> => {
    try {
      setLoading(true)
      setStateEmail(email)

      const { isAvailable } = await AuthRepository.checkRecovery(email)

      setEmailAvailable(isAvailable)

      if (isAvailable) {
        await AuthRepository.resetPassword(email)
        handleShowDialogStatus()
      }
    } catch (e) {
      // eslint-disable-next-line no-alert
      alert('Произошла ошибка!')
    } finally {
      setLoading(false)
      setSent(true)
    }
  }

  const handleResubmit = async (): Promise<void> => {
    try {
      const { isAvailable } = await AuthRepository.checkRecovery(stateEmail)

      setEmailAvailable(isAvailable)

      if (isAvailable) {
        await AuthRepository.resetPassword(stateEmail)
        setResent(true)
        handleShowDialogStatus()
      }
    } catch (e) {
      // eslint-disable-next-line no-alert
      alert('Произошла ошибка!')
    }
  }

  const isResendAvailable = isSent && isEmailAvailable

  const isSentcomponent = isSent && showDialogStatus && isEmailAvailable && (
    <DialogStatus title="Письмо отправлено на почту" status="success" onClose={handleHideDialogStatus} />
  )

  const isResentComponent = isResent && showDialogStatus && isEmailAvailable && (
    <DialogStatus title="Письмо повторно отправлено на почту" status="success" onClose={handleHideDialogStatus} />
  )

  return (
    <>
      <Helmet>
        <title>{AuthTitle.PasswordRecovery}</title>
        <script type="text/javascript" src="https://vk.com/js/api/openapi.js?169" />
        <meta name="auth" content={AuthTitle.PasswordRecovery} />
        <meta name="description" content={`${AuthTitle.PasswordRecovery} FuttyCoin`} />
      </Helmet>
      <AuthLayout>
        <AuthWrapper
          title={AuthTitle.PasswordRecovery}
          sidebar={<PasswordRecoverySidebar isResendAvailable={isResendAvailable} onResend={handleResubmit} />}
        >
          <Formik initialValues={values} onSubmit={handleSubmit} validationSchema={validationSchema}>
            {(props: FormikProps<PasswordRecoverPayload>) => {
              return (
                <PasswordRecoveryForm
                  {...props}
                  isLoading={isLoading}
                  isSent={isResendAvailable}
                  isResent={isResent && isEmailAvailable}
                />
              )
            }}
          </Formik>
        </AuthWrapper>
      </AuthLayout>
      {!isEmailAvailable && <RecoveryDialog />}
      {isSentcomponent}
      {isResentComponent}
    </>
  )
}
export default PasswordRecovery
