import React, { useEffect } from 'react'
import { useLocation } from 'react-router'

import { LoginResponse, LoginVKPayload } from 'shared/features/Auth/typings'
import AuthRepository from 'shared/features/Auth/AuthRepository'
import { SocialNetwork } from 'shared/types'

interface LoginVKData extends Omit<LoginVKPayload, 'id'> {
  uid: string
  photo_rec: string
}

interface VKWidgetProps {
  className?: string
  setAuthTokens(tokens: LoginResponse): void
}

const VKWidget: React.FC<VKWidgetProps> = ({ className, setAuthTokens }): React.ReactElement => {
  const { pathname } = useLocation()
  const isWidgets = (pathname === '/login' || pathname === '/registration') && VK_APP_AUTH_ID
  useEffect(() => {
    if (isWidgets) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      VK.init({ apiId: VK_APP_AUTH_ID })
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      VK.Widgets.Auth('vk_auth', {
        async onAuth(data: LoginVKData) {
          const { uid, first_name, last_name, photo, hash } = data
          const tokens: LoginResponse = await AuthRepository.loginWithSocialNetWork(
            {
              id: uid,
              first_name,
              last_name,
              photo,
              hash,
            },
            SocialNetwork.VK,
          )
          setAuthTokens(tokens)
        },
      })
    }
  }, [isWidgets])

  return <>{isWidgets && <div id="vk_auth" className={className} />}</>
}
export default VKWidget
