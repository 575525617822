import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, FormikProps } from 'formik'
import { Helmet } from 'react-helmet-async'

import AuthLayout from 'shared/core/layouts/AuthLayout/AuthLayout'
import AuthWrapper from 'shared/features/Auth/components/AuthWrapper'
import { LoginPayload } from 'shared/features/Auth/typings'
import LoginForm, { validationSchema } from 'shared/features/Auth/components/LoginForm'

import { AuthTitle } from '../../constants'
import { LoginSidebar } from '../../components/LoginSidebar'
import useRedirectFromLogin from '../../hooks/useRedirectFromLogin'
import useActivation from '../../hooks/useActivation'
import { actions } from '../../ducks'
import { getActivationIsLoading, getLoginIsLoading } from '../../ducks/selectors'
import { AuthLoader } from '../../components/AuthLoader'

const initialValues: LoginPayload = {
  username: '',
  password: '',
}

const Login: React.FC = (): React.ReactElement => {
  useRedirectFromLogin()
  useActivation()

  const dispatch = useDispatch()

  const loginIsLoading = useSelector(getLoginIsLoading)
  const activationIsLoading = useSelector(getActivationIsLoading)

  const handleSubmit = useCallback(
    (payload: LoginPayload) => {
      dispatch(actions.login(payload))
    },
    [dispatch],
  )

  return (
    <>
      <Helmet>
        <title>{AuthTitle.Login}</title>
        <script type="text/javascript" src="https://vk.com/js/api/openapi.js?169" />
        <meta name="auth" content={AuthTitle.Login} />
        <meta name="description" content={`${AuthTitle.Login} FuttyCoin`} />
      </Helmet>

      <AuthLayout>
        <AuthWrapper title={AuthTitle.Login} sidebar={<LoginSidebar />}>
          {activationIsLoading && <AuthLoader status="Активация..." />}
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
            {(props: FormikProps<LoginPayload>) => <LoginForm {...props} isLoading={loginIsLoading} />}
          </Formik>
        </AuthWrapper>
      </AuthLayout>
    </>
  )
}

export default Login
