import React from 'react'

import { Spinner } from 'shared/components/Spinner'
import Typography from 'shared/components/Typography'

import styles from './AuthLoader.module.css'

type Props = {
  status?: string
}

export const AuthLoader: React.FC<Props> = ({ status }) => (
  <div className={styles.loader}>
    <Spinner />
    <Typography size="m" weight="light" color="secondary">
      {status}
    </Typography>
  </div>
)
