import React from 'react'
import cn from 'clsx'

import Image from 'shared/components/Image'

import styles from './AuthForm.module.css'

type Props = {
  className?: string
}

export const AuthForm: React.FC<Props> = ({ className, children }) => {
  return (
    <div className={cn(className, styles.container)}>
      <div className={styles.form}>{children}</div>
      <Image src="images/auth_bg.png" className={styles.background} />
      <Image src="images/auth_border.png" className={styles.border} />
      <Image src="images/auth_underlayer.png" className={styles.underlayer} />
    </div>
  )
}
