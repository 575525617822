import React, { forwardRef, memo } from 'react'
import cn from 'clsx'

import styles from './Menu.module.css'

interface MenuProps {
  children: React.ReactElement | Array<React.ReactElement>
  height?: number | string
  open?: boolean
  className?: string
  classNameList?: string
  needInfiniteScroll?: boolean
}

const Menu = forwardRef<HTMLDivElement, MenuProps>(function Menu(props, ref): React.ReactElement | null {
  const { children, open = true, className, classNameList } = props

  if (!open) {
    return null
  }

  return (
    <div className={cn(styles.menu, className)} ref={ref}>
      <ul className={cn(styles.menu__list, classNameList)} role="menu">
        {React.Children.toArray(children)}
      </ul>
    </div>
  )
})

export default memo(Menu)
