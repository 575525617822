import React, { useCallback } from 'react'

export type CheckboxBaseProps = {
  id?: string
  name?: string
  type?: 'checkbox' | 'radio'
  label?: React.ReactElement | string
  value?: string | string[] | number
  checked?: boolean
  disabled?: boolean
  block?: boolean
  tabIndex?: number
  testId?: string
  className?: string
  onChange?(event: React.ChangeEvent<HTMLInputElement>): void
}

interface CheckboxBaseRootProps extends CheckboxBaseProps {
  children(props: CheckboxBaseProps): React.ReactElement
}

const CheckboxBase: React.FC<CheckboxBaseRootProps> = ({
  children,
  onChange,
  ...ownProps
}): React.ReactElement<CheckboxBaseProps> => {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      if (onChange) {
        onChange(event)
      }
    },
    [onChange],
  )

  return children({ ...ownProps, onChange: handleChange })
}

export const checkboxBaseDefaultProps: Partial<CheckboxBaseProps> = {
  type: 'checkbox',
  disabled: false,
  block: false,
}

CheckboxBase.defaultProps = checkboxBaseDefaultProps

export default CheckboxBase
