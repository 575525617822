import React, { useCallback, useEffect, useState } from 'react'

import { YandexHiddenFrameProps, YandexLoginProps } from '../../typings'

const YandexHiddenFrame: React.FC<YandexHiddenFrameProps> = ({ redirectTo }): React.ReactElement => {
  return React.createElement('iframe', {
    hidden: true,
    title: 'yandex-hidden-frame',
    src: redirectTo,
  })
}

const checkAccessKey = () => {
  const parts = window.location.href.split('#')
  const queryPartUrl = parts.length > 1 && parts[1] !== 'frame' ? parts[1] : null

  if (!queryPartUrl) {
    return null
  }

  let result = {}
  queryPartUrl.split('&').forEach(part => {
    const item = part.split('=')
    result = {
      ...result,
      [item[0]]: decodeURIComponent(item[1]),
    }
  })

  return result
}

const getYandexAuthUrl = (clientID: string, redirectUrl: string): string => {
  return `https://oauth.yandex.ru/authorize?response_type=token&client_id=${clientID}&redirect_uri=${encodeURIComponent(
    redirectUrl,
  )}&display=popup`
}

const getCurrentUrl = (): string => {
  let currentUrl = window.location.origin

  if (currentUrl[currentUrl.length - 1] === '/') {
    currentUrl = currentUrl.slice(0, currentUrl.length - 1)
  }

  return currentUrl
}

const YandexLogin: React.FC<YandexLoginProps> = ({ children, clientID, onSuccess }): React.ReactElement => {
  const [frameRedirectTo, setFrameRedirectTo] = useState<string | null>(null)

  const handleMessageFromPopup = useCallback((event: MessageEvent) => {
    if (event.data.source === 'yandex-login') {
      onSuccess(event.data.payload)
    }
  }, [])

  const onClick = () => {
    if (window.top) {
      sessionStorage.setItem('yandexAutoLoginDisabled', 'false')
      const currentUrl = window.location.href
      const requestUrl = getYandexAuthUrl(clientID, currentUrl)
      const h = 650
      const w = 550
      const y = window.top.outerHeight / 2 + window.top.screenY - h / 2
      const x = window.top.outerWidth / 2 + window.top.screenX - w / 2
      window.open(requestUrl, 'popup', `width=${w},height=${h},top=${y},left=${x}`)
      window.addEventListener('message', handleMessageFromPopup)
    }
  }

  const aki = checkAccessKey()
  const receiver = window.parent !== window ? window.parent : window.opener
  useEffect(() => {
    if (aki && receiver) {
      receiver.postMessage(
        {
          source: 'yandex-login',
          payload: aki,
        },
        window.location.origin,
      )
      window.close()
    }
  }, [aki, receiver])

  useEffect(() => {
    if (!aki && !receiver) {
      const autoLoginDIsabled = sessionStorage.getItem('yandexAutoLoginDisabled')
      const checkEntrance = autoLoginDIsabled !== 'true' ? getYandexAuthUrl(clientID, getCurrentUrl()) : null
      setFrameRedirectTo(checkEntrance)
      window.addEventListener('message', handleMessageFromPopup)
    }
    return () => {
      window.removeEventListener('message', handleMessageFromPopup)
    }
  }, [aki, receiver, frameRedirectTo])

  return React.createElement(
    'div',
    null,
    React.cloneElement(children, {
      onClick,
    }),
    frameRedirectTo &&
      React.createElement(YandexHiddenFrame, {
        redirectTo: frameRedirectTo,
      }),
  )
}

export default YandexLogin
